import { LinearBackoff, Websocket, WebsocketBuilder } from 'websocket-ts/lib';

import config from 'app/config';

export default class NotificationsService {
  ws: Websocket;

  openWsConnection(loginToken: string, forceReopen: boolean = false) {
    if (this.ws && !forceReopen) {
      return this.ws;
    }

    this.ws = new WebsocketBuilder(
      config.API_URL.replace('https', 'wss')
        .replace('http', 'ws')
        .replace('/api/v1/', '') + '/ws/v2/notifications/'
    )
      .onOpen((i, ev) => {
        this.ws?.send(
          JSON.stringify({ action: 'cdp_auth', token: loginToken })
        );
      })
      .onClose((i, ev) => {})
      .withBackoff(new LinearBackoff(0, 1000, 10000))
      .build();

    return this.ws;
  }
}
